<template>
  <div>
    <v-tabs v-model="tab" background-color="secondary" centered>
      <v-tab v-for="section in sections" :key="section.title">
        <v-icon v-if="section.icon" v-text="section.icon" left></v-icon>
        {{ section.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="section in sections" :key="section.title">
        <v-card flat>
          <v-card-text class="black--text">
            <component :is="section.component" @update-tab="tab = $event"></component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PureSpotSupport from '@/components/help/PureSpotSupport.vue';
import UserManagement from '@/components/help/UserManagement.vue';

export default {
  name: 'Help',
  components: {
    PureSpotSupport,
    UserManagement
  },
  data() {
    return {
      tab: null,
      sections: [
        {
          title: 'Pure-SPOT support',
          slug: 'pure-spot-support',
          component: PureSpotSupport
        },
        {
          title: 'User management',
          slug: 'user-management',
          component: UserManagement
        }
      ]
    }
  }
}
</script>