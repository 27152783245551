<template>
  <div id="user-management" class="pa-4">
    <h1 class="mb-4">
      {{ title }}
    </h1>

    <p>
      This guide explains how Pure-SPOT administration can create and manage Pure-SPOT users. The guide was last updated November 15, 2021.
    </p>

    <template v-for="section in sections">
      <h2 :id="section.id" class="mt-8 mb-4" :key="section.id">
        {{ section.title }}
      </h2>

      <v-row class="mb-6" v-for="(item, index) in section.items" :id="item.id" :key="section.id + index" align="top">
        <v-col cols="4">
          <p>
            {{ index + 1 }}. {{ item.text }}
          </p>
        </v-col>

        <v-col offset="1" cols="7">
          <v-card elevation="6">
            <v-img :src="require('@/assets/help/' + item.image)"></v-img>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'UserManagement',
  data() {
    return {
      id: 'user-management',
      title: 'User management for administrators',
      sections: [
        {
          id: 'accessing-admin-tools',
          title: 'Accessing administration tools',
          items: [
            {
              text: 'Start by selecting ‘Administration’ from the drop-down menu in the upper right hand corner.',
              image: 'admin-nav.png'
            },
            {
              text: 'You then get to your fleet’s administration site. There you can: Edit Organization, Link Organization and Add User.',
              image: 'admin-overview.png'
            },
            {
              text: 'This view is also where the administrator controls, which vessels in the fleet each user is allowed to view. E.g., if ‘BergeUser’ is no longer allowed to see the vessel ‘Berge Christobal’, the administrator simply checks off the box for this vessel (the tick disappears).',
              image: 'ship-access.png'
            }
          ]
        },
        {
          id: 'creating-new-users',
          title: 'Creating new users',
          items: [
            {
              text: 'Click on the "Add User" button.',
              image: 'add-user.png'
            },
            {
              text: 'A box with information to fill out appears. Once the correct information has been added, the ‘Add’ button is clicked. The difference between an ‘Administrator’ and ‘Regular user’ is that a regular user may only see the vessels in fleet as defined by the administrator. It is only possible for administrators to manage fleet and users.',
              image: 'add-user-dialog.png'
            },
            {
              text: 'The new user now appears in the overview.',
              image: 'new-user.png'
            },
            {
              text: 'Vessel(s) will need to be checked in the box(es), before the user can see the data when logging in. OBS! All administrators can allocate vessels themselves / change which vessels to see.',
              image: 'assign-to-new-user.png'
            }
          ]
        },
        {
          id: 'editing-existing-users',
          title: 'Editing existing users',
          items: [
            {
              text: 'It is only possible for administrators to edit the contact details. This can be done, by selecting "Edit Organization".',
              image: 'edit-organization.png'
            },
            {
              text: 'A pop-up window appears, in which the contact details can be updated. The ‘Save’ button will need to be selected for the changes to take effect.',
              image: 'edit-organization-dialog.png'
            }
          ]
        }
      ]
    }
  }
}
</script>