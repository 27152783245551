<template>
  <div id="pure-spot-support" class="pa-4">
    <h1 class="mb-4">
      {{ title }}
    </h1>

    <p>
      Pure-SPOT is PureteQ’s newly developed software, a ‘Scrubber Performance Optimization Tool’ (SPOT). The software will enable sharing of learnings between crews, resulting in lower energy consumption and other OPEX.
    </p>

    <p>
      The software is a perfect match to new IMO regulation on EED(X)I & CCI, when well to wake principle applies. This software will allow for across ship / fleet learnings to crews, hence reduction of cost and we see crews competing on operation of the scrubbers.
    </p>
    <p>
      If you are looking for more information or need support on how to utilize Pure-SPOT, please reach out to us on <a href="mailto:pure-spot@pureteq.com">pure-spot@pureteq.com</a>. Please remember to include username, screenshot and detailed description of your query when contacting us.
    </p>

    <p>
      We will respond to all queries within 24 hours in weekdays, regarding;
    </p>

    <ul>
      <li>
        user management;
      </li>
      <li>
        data interpretation; 
      </li>
      <li>
        registration of new vessel(s);
      </li>
      <li>
        changes of vessel details;
      </li>
      <li>
        etc.
      </li>
    </ul>

    <v-alert class="mt-4" type="info" text>
      Our ‘Early Access’ clients will benefit from receiving Pure-SPOT assistance in a reasonable scope, free of charge, until July 1st, 2022. After July 1st, 2022, all Pure-SPOT assistance will be invoiced according to Service Agreement.
    </v-alert>

    <p>
      The software you have accessed on this Site is still in a testing phase and is provided on an “As is” and “As available” basis and may contain defects. A primary purpose of this release and testing phase is to obtain feedback on software performance and the identification of defects.
    </p>

    <p>
      We encourage all users of Pure-SPOT to submit their feedback to our new software on <a href="mailto:pure-spot@pureteq.com">pure-spot@pureteq.com</a>. Feedback on software performance is as much appreciated as feedback on the content, user friendliness, etc.
    </p>

    <p>
      We will take all feedback received into consideration in our further development of Pure-SPOT.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PureSpotSupport',
  data() {
    return {
      id: 'pure-spot-support',
      title: 'Pure-SPOT support'
    }
  }
}
</script>
